import { createStore, combineReducers } from 'redux';
import { log, setLogUID, setFunctions, setLogOrgID } from './logging/logging';
import { getSentryReleaseID, storage } from './utilities';
import { isDev, checkOrg, checkPro, isElectronApp, getConnectedConfigOptions, orgPref, CURRENT_PLATFORM, isAISnippetsAllowedPageContent } from './flags';
import equals from 'fast-deep-equal';
import { setLocale } from './locales';
import Sync from './Sync/Sync';
import './firebase_init'; // Initialize firestore app
import { getAuth, onAuthStateChanged, signInWithCustomToken, getIdToken, getIdTokenResult, signOut } from 'firebase/auth';
import {
  enableMultiTabIndexedDbPersistence,
  clearIndexedDbPersistence,
  getFirestore,
  terminate,
  onSnapshot
} from 'firebase/firestore';
import { getTBFunctions, makeRef } from './firebase_utilities';

// @ts-ignore
import snippetSound from '../sounds/click.wav';

import { limitationsState, usageCount, useFeature } from './components/Version/limitations';
import { findCursorPlacementAndEmptyInsert, sendMessageToClient, processSideChannelItems, getClipboard, getSiteData, handleRemoteCommand, writeToLocalStorage, readFromLocalStorage } from './desktop_utilities';
import { addBreadcrumb, captureException } from '@sentry/browser';
import { getCleanedAddons, convertReplacementPartsToText, isEmptyInsert } from './snippet_processor/DownstreamProcess';
import { callCompletedFn, deleteConfigDefCircularKeys, evaluateDeltaUsingConfigDef, makeConfigMaker, makeReplacementMaker, runErrorBlockDownstream, runFinishBlockDownstream } from './snippet_processor/EvaluatorProcess';
import { getTokenCredentials } from './credentials';
import { getApp } from 'firebase/app';
import { PROPAGATE_ERROR, getDataFromClient } from './desktop_shared_utils';
import { AI_CONFIG_KEY, AI_SNIPPETS_MODEL_KEY, getAIConfig, getContextData, getHostData, getModelDetails, getSelfUserInfo, getUserMemberData, isBlaze, isPayingPro } from './desktop_ai_utilities';
import { prioritizationHandlerMaker } from './prioritization_handler';

let formIdCount = Math.round(Math.random() * 1000000); 
/** @type {import('./snippet_processor/DownstreamProcess').NextFormDataType} */
let nextFormData;
/** @type {import("redux").Store} */
export let store;
/** @type {import('firebase/firestore').DocumentReference} */
let usersSettingsRef = undefined;
/** @type {import('firebase/firestore').DocumentReference} */
let usersNotificationsRef = undefined;

const snd = new Audio(snippetSound);
let isInsertionBlockedInChromeBrowsers = true;
let appName = 'Text Blaze BETA.exe';

// It will be true for AI Blaze desktop
const IS_AI_BLAZE_DESKTOP = new URLSearchParams(window.location.search).get('is_ai') === 'true';

function playSnippetSound() {
  if (snd) {
    try {
      snd.play().catch(error => {
        captureException(error);
      });
    } catch (error) {
      captureException(error);
    }
  } else {
    captureException('Error in playing snippet sound: snd is undefined');
  }
}

/** @type {import('./Sync/Sync').default} */
let sync;
function createSync() {
  sync = new Sync({
    storage,
    log,
    entityPostProcess: (type, entity) => {
      if (type === 'group') {
        entity.options = Object.assign({
          trigger: 'word'
        }, entity.options || {});
      } else if (type === 'snippet') {
        entity.delta = entity.content.delta.toUint8Array();
      }
  
      return entity;
    },
    dispatch: function(data) {
      if (data.type === 'DATA_CHANGE') {
        // send update to assistant
        sendAssistantDataToApp();
      } else if (data.type === 'GROUP_PERMISSION_DENIED') {
        console.warn('Permission denied to access group: ', data.groupId, data.errorCount);
        if (data.errorCount > 8) {
          console.warn('Ending attempt to access group: ', data.groupId);
          data.unSubscribe();
          // TODO: clean up the group we lost access to
          return true; // cancel the retry
        }
      }
    },
    getUserState: () => store.getState().userState,
    isDashboard: false
  });

  store.subscribe(() => {
    sync.userChanges();
  });

  return sync;
}

firestoreEnablePersistence()
  .catch(err => {
    // It is necessary so that when trying to set persistence
    // for an already used firestore, an exception is not thrown.
    // This is necessary for a webpack's hot reload just on dev environment,
    // as long as we enable persistence in the body of this file.
    if (isDev() && String(err).includes('already been started and persistence can no longer be enabled')) {
      return;
    }
    console.error('Firestore persistence enabling on start', err);
  });

async function firestoreEnablePersistence() {
  await enableMultiTabIndexedDbPersistence(getFirestore());
}

// Key for firestore clear persistence lock
const LOCK_KEY = 'firestore_clear_persistence_lock';

/**
 * we should clear persistence only once for all active windows and tabs
 */
function firestoreClearPersistenceLock() {
  let lockTime = Number(localStorage.getItem(LOCK_KEY)) || 0;
  let currTime = Date.now();
  if (lockTime + 20 * 1000 <= currTime) {
    localStorage.setItem(LOCK_KEY, String(currTime));
    return true;
  }
  return false;
}

async function firestoreReset() {
  await terminate(getFirestore());
  if (firestoreClearPersistenceLock()) {
    await clearIndexedDbPersistence(getFirestore());
  }
  await firestoreEnablePersistence();

  // to reset defined below firestore collections and documents references
  firestoreRefsReset();
}

setFunctions(getTBFunctions());


function firestoreRefsReset() {
  usersSettingsRef = undefined;
  usersNotificationsRef = undefined;
}


let usersSettingsSubscription = undefined;
let usersReadonlySubscription = undefined;
let usersNotificationsSubscription = undefined;
let configSubscription = undefined;


function gettingAuthFailed() {
  store.dispatch({
    type: 'FAILED_GET_CREDENTIALS'
  });
}


/**
 * @typedef {object} UserStateDef
 * @property {string=} uid
 * @property {string=} email
 * @property {string=} displayName
 * @property {boolean=} emailVerified
 * @property {boolean=} readonlyLoaded
 * @property {boolean=} settingsLoaded
 * @property {boolean=} is_pro
 * @property {boolean=} isLoaded
 * @property {boolean=} isRelogin - if true, the user manually logged in via the auth component screen and it's not their first login
 * @property {string=} referral_code
 * @property {number=} credit_balance
 * @property {string=} locale
 * @property {object[]=} notifications
 * @property {object[]=} messages
 * @property {object=} capabilities_adjustment
 * @property {{ capabilities_plan?: string, last_viewed_notifications_at?: number, snippet_editing_chips_enabled?: boolean, snippet_editing_chips_enabled_2?: boolean, databases?: Object<string, {order: number, favorite: boolean}>, last_viewed_app?: string, has_used_windows?: boolean, has_used_mac?: boolean, has_used_extension?: boolean, is_windows_app_disabled_in_chrome_browsers?:boolean, is_mac_app_disabled_in_chrome_browsers?: boolean}=} options
 * @property {Object<string, {collapsed: boolean, disabled: boolean, order: number}>=} groups
 * @property {{id: string, type: string}=} org
 * @property {TeamObjectType[]=} teams
 * @property {object=} billing_alert
 * @property {object=} pro_grant_expiry
 * @property {import('firebase/auth').User=} firebaseUser
 * @property {object=} firebaseMetadata
 * @property {object=} usage
 * @property {number=} createdTimestamp
 * @property {{shown_timestamp: number, rating_timestamp: number, rating: number, chrome_webstore_rated: boolean}=} rating
 * @property {number=} will_cancel
 * @property {boolean=} disabled_groups_collapsed
 * @property {boolean=} team_groups_collapsed
 * @property {import('firebase/auth').UserInfo[]=} providerData
 * @property {object=} quest
 * @property {Object<string, number>=} views
 * @property {Object<string, any>=} member_fields_data
 * @property {object=} priorities
 * @property {string[]=} dismissed_notifications
 * @property {Object<string, InstalledAddonType>=} addons
 * @property {boolean=} mvp
 * @property {string=} photoUrl
 * @property {boolean=} isAndroidAssistantActive
 * @property {number=} referrals
 */

/**
 * The User Reducer
 * 
 * @param {UserStateDef} state
 * 
 * @return {UserStateDef}
 */
const userReducer = function(state = {
  isLoaded: false
}, action) {
  switch (action.type) {
  case 'USER_LOGIN':
    if (usersSettingsSubscription) {
      usersSettingsSubscription();
      usersSettingsSubscription = null;
    }
    if (usersReadonlySubscription) {
      usersReadonlySubscription();
      usersReadonlySubscription = null;
    }
    if (usersNotificationsSubscription) {
      usersNotificationsSubscription();
      usersNotificationsSubscription = null;
    }
    if (configSubscription) {
      configSubscription();
      configSubscription = null;
    }

    if (action.user.uid) {
      window['xuid'] = action.user.uid;
      usersSettingsRef = makeRef('users_settings', action.user.uid);
      
      configSubscription = onSnapshot(makeRef('keys', 'web_config'), (snapshot) => {
        if (snapshot.exists()) {
          store.dispatch({
            type: 'NEW_CONFIG',
            data: snapshot.data()
          });
        }
      });

      setLogUID(action.user.uid);

      // send the user id to client for sentry logging
      sendMessageToClient({
        type: 'log-uid',
        data: action.user.uid
      });
      
      usersSettingsSubscription = storage.onSnapshot(usersSettingsRef, (snapshot) => {

        if (window['deleting_user']) {
          // if we don't check for this, text blaze may recreate the initial groups while deleting the user
          return;
        }

        if (snapshot.exists()) {
          let data = snapshot.data();
          
          // Can have unnecessary changes due to metadata inclusion
          // So we check before pushing
          let storeState = store.getState().userState;
          for (let key in data) {
            if (!equals(data[key], storeState[key])) {
              store.dispatch({
                type: 'USER_SETTINGS_UPDATED',
                data
              });
              break;
            }
          }
        }
      }, undefined, { includeMetadataChanges: true });
      usersReadonlySubscription = storage.onSnapshot(makeRef('users_readonly', action.user.uid), (snapshot) => {
        let data;
        if (snapshot.exists()) {
          data = snapshot.data();
        } else {
          data = {
            is_pro: false
          };
        }
        
        // Make sure the org and teams gets overwritten if removed
        if (!data.org) {
          data.org = null;
        }
        if (!data.teams) {
          data.teams = null;
        }
        if (!data.billing_alert) {
          data.billing_alert = null;
        }

        store.dispatch({
          type: 'USER_READONLY_UPDATED',
          data
        });

        setLogOrgID(data.org && data.org.id);
      }, undefined, { includeMetadataChanges: true });
      if (action.user.emailVerified) {
        usersNotificationsRef = makeRef('users_notifications', action.user.email);
        usersNotificationsSubscription = storage.onSnapshot(usersNotificationsRef, (snapshot) => {
          let data;
          if (snapshot.exists()) {
            data = snapshot.data();
          }
          store.dispatch({
            type: 'USER_NOTIFICATIONS_UPDATED',
            data
          });
        });


        getIdTokenResult(action.user.firebaseUser).then((token) =>{
          let messageMatches = ['e:' + action.user.email];
          if (token.claims.org) {
            messageMatches.push('o:' + /** @type {{id: string}} */(token.claims.org).id);

            // note this can only 'array-contains-any' can only query 10 options
            // users shouldn't add more than 5 teams, but we limit it here just in case
            // as they technically could be added to more
            let teams = Object.keys(token.claims.teams).slice(0, 5);
            for (let team of teams) {
              messageMatches.push('t:' + /** @type {{id: string}} */(token.claims.org).id + '///' + team);
            }
          }
        });
      }
      sendMessageToClient({
        type: 'auth-status',
        data: {
          loggedIn: true,
          email: action.user.email,
          uid: action.user.uid
        }
      });
      return Object.assign({}, state, { 
        firebaseMetadata: action.user.firebaseUser.metadata
      }, action.user);
    } else {
      setLogOrgID(null);
      sendMessageToClient({
        type: 'auth-status',
        data: {
          loggedIn: false
        }
      });
      return Object.assign({}, action.user);
    }
  case 'USER_SETTINGS_UPDATED':
    if (snd && action?.data?.snippet_volume !== undefined) {
      snd.volume = action.data.snippet_volume;
    }
    setLocale(action.data.locale);
    isInsertionBlockedInChromeBrowsers = !!action?.data?.options?.[`is_${CURRENT_PLATFORM}_app_disabled_in_chrome_browsers`];
    sendMessageToClient({
      type: 'user-settings-data',
      data: (action?.data || {})
    });
    return Object.assign({}, state, { settingsLoaded: true }, action.data);
  case 'USER_READONLY_UPDATED':
    let dataReadonly = action.data || {};
    if (!state || !equals(state.org, dataReadonly.org) || !equals(state.teams, dataReadonly.teams)) {
      // We need to update the id token so changed custom claims are available
      // to the Firestore rules.
      if (getAuth().currentUser) {
        getIdToken(getAuth().currentUser, true);
      }
    }
    return Object.assign({}, state, { readonlyLoaded: true } , dataReadonly);
  case 'USER_NOTIFICATIONS_UPDATED':
    let dataNotifications = action.data || {};
    return Object.assign({}, state, { readonlyLoaded: true } , { notifications: dataNotifications });
  default:
    return state;
  }
};

/**
 * @typedef {object} ConfigDef
 * @property {number} MIN_WEB_VERSION
 * @property {number} MIN_MICRO_VERSION
 * @property {Object<string, {parent: string, shared: import("./components/Version/usageLimitations").LimitationsDef, skus: Object<string, import("./components/Version/usageLimitations").LimitationsDef>}>} plans
 * @property {boolean} configLoaded
 * @property {Object<string, object>} experiments map of experiment name to its depoyment fraction
 */

/**
 * The config Reducer
 * 
 * @param {ConfigDef} state
 * 
 * @return {ConfigDef}
 */
const configReducer = function(state = {
  MIN_WEB_VERSION: 0,
  MIN_MICRO_VERSION: 0,
  plans: {},
  experiments: { WIDGET_PERCENT: 0, WIDGET_START_DATE_NEW: { seconds: 0, nanoSeconds: 0 } },
  configLoaded: false
}, action) {
  switch (action.type) {
  case 'NEW_CONFIG':
    return Object.assign({
      configLoaded: true
    }, action.data);
  default:
    return state;
  }
};
let combinedReducers = combineReducers({
  userState: userReducer,
  config: configReducer,
});


const rootReducer = (state, action) => {
  let newState = combinedReducers(state, action);
  return newState;
};


/** @typedef {ReturnType<combinedReducers>} RootState */


/** @typedef {import("type-fest").PartialDeep<RootState>} TestRootState */
/** @typedef {import("type-fest").PartialDeep<UserStateDef>} TestUserState */

store = createStore(rootReducer);
createSync();

/**
 * The current user's UID.
 * 
 * @return {string}
 */
function uid() {
  let state = store.getState();
  if (!state || !state.userState) {
    return undefined;
  }
  return state.userState.uid;
}

/**
 * Whether the current user is Pro.
 * 
 * @return {boolean}
 */
function isPro() {
  return checkPro(store.getState());
}


/**
 * Whether the current user is a business user.
 * 
 * @return {boolean}
 */
function isOrg() {
  return checkOrg(store.getState());
}


storage.setStore({
  uid,
  store
});

/** @type {'windows'|'mac'} */
let desktopType;
onAuthStateChanged(getAuth(), function(user) {
  if (user) {
    log({ category: 'Authentication', action: 'Logged in', label: { source: desktopType } });

    store.dispatch({
      type: 'USER_LOGIN',
      user: {
        isLoaded: true,
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        photoUrl: user.photoURL,
        uid: user.uid,
        createdTimestamp: (new Date(user.metadata.creationTime)).getTime(),
        firebaseUser: user,
        providerData: user.providerData
      }
    });
  } else {
    store.dispatch({
      type: 'USER_LOGIN',
      user: {
        isLoaded: true
      }
    });
    firestoreReset();
  }

  getDataFromClient({
    type: 'get-version'
  }, PROPAGATE_ERROR).then((data) => {
    if (data && data.version) {
      const { executableName, platform, version, ...logData } = data;
      desktopType = platform || 'windows';
      log({
        category: 'desktop',
        action: desktopType + ' version logging',
        label: {
          ...logData,
          appVersion: version,
          platformRelease: getSentryReleaseID(),
          timestamp: Date.now(),
          source: desktopType
        }
      });
    }
    if (data?.executableName) {
      appName = data.executableName;
    }
    sendMessageToClient({
      type: 'restricted-apps',
      data: getRestrictedApps()
    });
  }).catch(error => {
    captureException('Error in logging version: ', error);
  });
});

/**
 * @typedef {typeof runFinishBlock} RunFinishBlockType
 * @typedef {typeof runErrorBlock} RunErrorBlockType
 * @typedef {typeof showCompletedNotification} ShowCompletedNotificationType
 */

/**
 * @typedef {Object} RunFinishBlockParamsType
 * @property {string} command
 * @property {import('./snippet_processor/ParseNode').default} finishBlock 
 * @property {string} text 
 * @property {'success'|number} status 
 * @property {import('./snippet_processor/DownstreamProcess').NativeRemoteBlockDataType} blockData 
 * 
 * @param {RunFinishBlockParamsType["command"]} command 
 * @param {RunFinishBlockParamsType["finishBlock"]} finishBlock 
 * @param {RunFinishBlockParamsType["text"]} text 
 * @param {RunFinishBlockParamsType["status"]} status 
 * @param {RunFinishBlockParamsType["blockData"]} blockData 
 * 
 */
async function runFinishBlock(command, finishBlock, text, status, blockData) {
  await runFinishBlockDownstream(command, finishBlock, text, status, blockData, makeConfig, showNotification);
}

/**
 * @typedef {Object} RunErrorBlockParamsType
 * @property {string} command
 * @property {import('./snippet_processor/ParseNode').default} errorBlock
 * @property {string} err
 * @property {'error'|number} status 
 * @property {import('./snippet_processor/DownstreamProcess').NativeRemoteBlockDataType} blockData 
 * 
 * @param {RunErrorBlockParamsType["command"]} command 
 * @param {RunErrorBlockParamsType["errorBlock"]} errorBlock 
 * @param {RunErrorBlockParamsType["err"]} err 
 * @param {RunErrorBlockParamsType["status"]} status 
 * @param {RunErrorBlockParamsType["blockData"]} blockData 
 * 
 */
async function runErrorBlock(command, errorBlock, err, status, blockData) {
  await runErrorBlockDownstream(command, errorBlock, err, status, blockData, makeConfig, showNotification);
}

/**
 * 
 * @param {import('./snippet_processor/ParseNode').default} completedFn 
 * @param {[string, number]} responseData 
 * @param {string} notificationHeader 
 * @param {object} state
 */
async function showCompletedNotification(completedFn, responseData, notificationHeader, state) {
  const res = await callCompletedFn(state, completedFn, responseData);
        
  // Only show a notification if the message is non-empty string
  if (res) {
    showNotification({
      title: notificationHeader,
      message: res
    });
  }
}

/**
 * 
 * @returns {Array<string>}
 */
function getRestrictedApps() {
  let RESTRICTED_APPLICATIONS = [];
  let RESTRICTED_PWA_APPLICATIONS = [];
  switch (desktopType) {
  case 'windows': 
    RESTRICTED_APPLICATIONS = ['chrome.exe', 'msedge.exe', 'brave.exe', 'opera.exe', 'wavebox.exe', 'vivaldi.exe', 'Arc.exe'];
    break;
  case 'mac':
    RESTRICTED_APPLICATIONS = ['Google Chrome.app', 'Google Chrome Beta.app', 'Google Chrome Dev.app', 'Opera.app', 'Opera Beta.app', 'Opera Developer.app', 'Brave Browser.app', 'Brave Browser Beta.app', 'Brave Browser Nightly.app', 'Microsoft Edge.app', 'Microsoft Edge Canary.app', 'Microsoft Edge Beta.app', 'Microsoft Edge Dev.app', 'Wavebox.app', 'Vivaldi.app', 'Vivaldi Snapshot.app', 'Arc.app'];
    RESTRICTED_PWA_APPLICATIONS = ['Chrome Apps.localized', 'Brave Browser Apps.localized', 'Brave Browser Beta Apps.localized', 'Brave Browser Nightly Apps.localized', 'Edge Apps.localized'];
    break;
  default:
    break;
  }
  return RESTRICTED_APPLICATIONS.concat(RESTRICTED_PWA_APPLICATIONS);
}

function isRestrictedApp(owner, tryMeBoxActive = false) {
  let isInsertionBlocked = false;
  if (owner) {
    if (isInsertionBlockedInChromeBrowsers) {
      isInsertionBlocked = getRestrictedApps().filter(app => owner.endsWith(app)).length > 0;
    }

    if (!tryMeBoxActive && owner.endsWith(appName)) {
      isInsertionBlocked = true;
    }    
  }
  return isInsertionBlocked;
}

/**
 * Ensure that content is not derived from a user input string.
 * 
 * @param {string} content 
 * @param {boolean} [showUpgrade] 
 */
function notifyClient(content, showUpgrade = false, installExtension = false) {
  showNotification({ content, showUpgrade, installExtension });
}

function getAllDataForAISnippet() {
  /** 
   * The omitted properties here are passed in AppEmbed/App.js
   * @type {Omit<Parameters<import('./components/AutoWriteChat/AutoWriteChat.js').default>[0], 'autowriteRef'|'model'|'inputRef'|'taskName'|'hostData'> & { testAIEndpoint: string, }}
   */
  const data = {
    insertable: true,
    isBlazeUser: isBlaze(),
    userIsPro: isPro(),
    isOrg: isOrg(),
    userMemberData: getUserMemberData(sync, getState()),
    userIsPayingPro: isPayingPro(getState()),
    userInfo: getSelfUserInfo(),
    isPageContentAllowed: isAISnippetsAllowedPageContent(getState()),
    // Only used for testing in GitLab CI
    testAIEndpoint: '',
    config: getAIConfig(),
  };
  return data;
}

function getInitialPromptForAISnippet() {
  const modelFullData = getModelDetails();

  return Object.assign(nextFormData, modelFullData);
}

/**
 * @typedef {{ data: Awaited<ReturnType<typeof getAllDataForAISnippet>>, frame: Awaited<ReturnType<typeof getHostData>>, initial: Awaited<ReturnType<typeof getInitialPromptForAISnippet>>, }} AISnippetDataType
 * 
 * @returns {Promise<AISnippetDataType>}
 */
async function getDataForAISnippet() {
  // Now build the data needed for sending
  const [data, frame, initial] = await Promise.all([getAllDataForAISnippet(), getHostData(nextFormData.configDef), getInitialPromptForAISnippet()]);
  
  // Reset the snippet data as the AI snippet has been triggered once
  nextFormData = null;

  // Now send the data to the embed iframe
  return {
    data,
    frame,
    initial,
  };
}

async function handleAutoWriteRequest(request) {
  switch (request.subType) {
  case 'saveModel':
    writeToLocalStorage(AI_SNIPPETS_MODEL_KEY, request.model);
    break;
  case 'toggleConfig':
    const config = getAIConfig();
    config[request.name] = !config[request.name];
    writeToLocalStorage(AI_CONFIG_KEY, config);
    break;
  case 'embedLoaded':
    if (nextFormData) {
      // User has already triggered an AI snippet, so we should immediately
      // request the data and resolve the promise
      return getDataForAISnippet();
    }
    break;
  default:
    break;
  }
}

/** @type {boolean} */
let pageContentAccepted;

async function runPendingAITask() {
  const text = await getDataFromClient({ type: 'ai-chat', subType: 'get-preceding-text', data: { insertionType: /** @type {InsertionType} */ (nextFormData.configDef.insertionType) } });
  nextFormData.aiData.precedingText = text;
  await getContextData(pageContentAccepted);
  sendMessageToClient({
    type: 'load-ai-window',
    insertionType: /** @type {InsertionType} */ (nextFormData.configDef.insertionType)
  });
}

/**
 * 
 * @typedef {Object} ShowCompletedNotificationParamsType
 * @property {import('./snippet_processor/ParseNode.js').default} completedFn 
 * @property {[string, number]} responseData 
 * @property {string} notificationHeader  
 * 
 * @typedef {{ type: 'get-id-token' } |
 * { type: 'get-auth-status' } |
 * { type: 'get-credentials' } |
 * { type: 'play-snippet-sound' } |
 * { type: 'check-for-replacement', data: CheckForReplacementData } |
 * { type: 'form-request-snippet', data: string } |
 * { type: 'refresh-assistant-data', data: { application: string } } |
 * { type: 'signout-checker' } |
 * { type: 'authenticate-checker', data: { type: 'credentials', token: string }} |
 * { type: 'log-form-usage', data: LogReplacementType } |
 * { type: 'call-completed', data: ShowCompletedNotificationParamsType} |
 * { type: 'run-finish-block', data: RunFinishBlockParamsType } |
 * { type: 'run-error-block', data: RunErrorBlockParamsType } |
 * { type: 'autowrite', subType: 'saveModel', model: import('./components/AppEmbed/App').AIModelType } |
 * { type: 'autowrite', subType: 'toggleConfig', name: string } |
 * { type: 'autowrite', subType: 'embedLoaded' } |
 * { type: 'get_credentials', data: string } |
 * { type: 'get-snippets-by-shortcut', shortcut: string } |
 * { type: 'replacement-handler-finished' }
 * } CheckerSendMessageType
 */

if (isElectronApp()) {
  // remove message listeners first so that it does not attach two listeners on refresh
  window['electronAPI'].removeMessageListener();

  window['electronAPI'].attachMessageListener(callback);
  /**
   * @param {CheckerSendMessageType} dataReceived 
   * @returns 
   */
  async function callback(_, dataReceived) {
    if (dataReceived && dataReceived.type) {
      switch (dataReceived.type) {
      case 'authenticate-checker':
        if (dataReceived.data.type !== 'credentials') {
          return;
        }
        if (!dataReceived.data.token) {
          return gettingAuthFailed();
        }
        try {
          await signOut(getAuth());
          await signInWithCustomToken(getAuth(), dataReceived.data.token);
        } catch (e) {
          notifyClient('There was a problem in logging you in. Please restart the app and try again.');
          console.warn(e);
          gettingAuthFailed();
          sendMessageToClient({
            type: 'auth-status',
            data: {
              loggedIn: false
            }
          });
        }
        break;
      case 'signout-checker':
        await signOut(getAuth());
        localStorage.clear();
        break;
      case 'get-auth-status':
        if (getAuth().currentUser) {
          sendMessageToClient({
            type: 'auth-status',
            data: {
              loggedIn: true,
              email: getAuth().currentUser.email,
              uid: getAuth().currentUser.uid
            }
          });
        } else {
          sendMessageToClient({
            type: 'auth-status',
            data: {
              loggedIn: false
            }
          });
        }
        break;
      case 'refresh-assistant-data':
        sendAssistantDataToApp(dataReceived.data?.application);
        break;
      case 'check-for-replacement':
        if (isRestrictedApp(dataReceived.data.owner, dataReceived.data.tryMeBoxActive)) {
          return;
        }
    
        handleReplacement(dataReceived.data, (res) => {
          if (res) {
            if (res.replacement) {
              findCursorPlacementAndEmptyInsert(res);
              // send back replacement data to main process
              sendMessageToClient({
                type: 'replacement-data',
                data: res
              });
            } else if (res.aiData) {
              // Handle AI snippets separately
              sendMessageToClient({
                type: 'ai-data',
                data: res
              });
            }
          }
        });
        break;
      case 'play-snippet-sound':
        playSnippetSound();
        break;
      case 'log-form-usage':
        logReplacement(dataReceived.data);
        break;
      case 'form-request-snippet':
        let snippets = sync.getSnippetsByShortcut(dataReceived.data);
        let snippetData = snippets && snippets.length ? JSON.stringify(snippets[0].data) : null;
        sendMessageToClient({
          type: 'send-snippet-on-request',
          data: snippetData
        });
        break;
      case 'get-id-token':
        let fbToken;
        // It can fail due to a network issue which leads to db* commands failing with the "Could not run SQL" error. 
        // The reason for using try-catch is to avoid blocking process side channel and loading data.
        try {
          fbToken = await getIdToken(getAuth().currentUser);
        } catch {}
        sendMessageToClient({
          type: 'id-token',
          data: { fbToken }
        });
        break;
      case 'get-credentials':
        let credentials = {};
        /** @type {string} */
        let error = '';
        // It can fail due to a network issue which leads to dashboard sign-in failure. 
        // We are handling it by sign-out from the checker as well on desktop app.
        try {
          credentials = await getTokenCredentials(getApp());
        } catch (e) {
          error = e?.message || '';
        }
        sendMessageToClient({
          type: 'credentials',
          data: { credentials, error },
        });
        break;
      case 'call-completed':
        showCompletedNotification(dataReceived.data.completedFn, dataReceived.data.responseData, dataReceived.data.notificationHeader, getState());
        break;
      case 'autowrite':
        handleAutoWriteRequest(dataReceived).then((response) => {
          sendMessageToClient({
            type: 'autowrite',
            data: response
          });
        });
        break;
      case 'get_credentials':
        if (dataReceived.data === 'token') {
          sendMessageToClient({
            type: 'get_credentials',
            data: await getTokenCredentials(getApp())
          });
        } else if (dataReceived.data === 'JWT') {
          const token = await getIdToken(getAuth().currentUser);
          sendMessageToClient({
            type: 'get_credentials',
            data: { provider: 'JWT', data: token, }
          });
        }
        break;
      case 'get-snippets-by-shortcut':
        if (dataReceived.shortcut) {
          sendMessageToClient({
            type: 'get-snippets-by-shortcut',
            data: sync.getSnippetsByShortcut(dataReceived.shortcut).map(s => s.data)
          });
        } else {
          sendMessageToClient({
            type: 'get-snippets-by-shortcut',
            data: []
          });
        }
        break;
      case 'replacement-handler-finished':
        runPendingAITask();
        break;
      case 'run-finish-block':
        runFinishBlock(dataReceived.data.command, dataReceived.data.finishBlock, dataReceived.data.text, dataReceived.data.status, dataReceived.data.blockData);
        break;
      case 'run-error-block':
        runErrorBlock(dataReceived.data.command, dataReceived.data.errorBlock, dataReceived.data.err, dataReceived.data.status, dataReceived.data.blockData);
        break;
      // Backward compatibility
      // We can remove it when all the users are shifted to app handling 
      // both process and empty replacement in the main process itself
      // @ts-ignore
      case 'process-replacement':
        sendMessageToClient({
          type: 'process-replacement-response',
          // @ts-ignore
          data: convertReplacementPartsToText(dataReceived.data.replacement)
        });
        break;
      // @ts-ignore
      case 'empty-replacement':
        sendMessageToClient({
          type: 'empty-replacement-response',
          // @ts-ignore
          data: isEmptyInsert(dataReceived.data.type, dataReceived.data.replacement)
        });
        break;
      default:
        // @ts-ignore
        captureException('Electron unknown msg received:' + dataReceived.type);
        break;
      }
    }
  }
  sendMessageToClient({ type: 'listeners-attached' });
}

const { updatePrioritizationOfSnippets, getPrioritizedSnippets } = prioritizationHandlerMaker(async (key) => readFromLocalStorage(key), async (key, value) => writeToLocalStorage(key, value));

/**
 * @param {ShortcutMessage} msg 
 * @returns {[any, string, string]} 
 */
function getReplacement(msg) {
  let replacement;
  let appendage = ''; // Text to add after the snippet
  let trigger = ''; // for {snippet: trigger}

  const activeShortcuts = sync.activeShortcuts({ abSnippets: IS_AI_BLAZE_DESKTOP ? 'only' : undefined, tbSnippets: IS_AI_BLAZE_DESKTOP ? undefined : 'only' });
  if (msg.shortcut) {
    trigger = msg.shortcut;
    let shortcut = msg.shortcut.toLocaleLowerCase();
    let trimmed = false;
    // Trim the shortcut from left to right
    // If the group is an anywhere group or a started by group we use this
    // Also omnibox always matches even if the group is 'standalone'
    while (shortcut && !replacement) {
      if (activeShortcuts[shortcut]) {
        const replacements = activeShortcuts[shortcut];
        for (let i = 0; i < replacements.length; i++) {
          const group = replacements[i].group;
          if ((!group || (group.data.options && group.data.options.trigger !== 'standalone')) && (!trimmed || (group && group.data.options && group.data.options.trigger === 'anywhere'))) {
            replacement = replacements[i];
            break;
          }
        }
      }
      if (!replacement) {
        shortcut = shortcut.slice(1);
        trigger = trigger.slice(1);
        trimmed = true;
      }
    }
    // If we have no match and we ended with a non character,
    // let's do standalone matches
    if (!replacement) {
      shortcut = msg.shortcut.toLocaleLowerCase();
      trigger = msg.shortcut;

      // [\P{L}] character class matches a character that is the complement of a unicode letter
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Regular_expressions/Unicode_character_class_escape
      // https://unicode.org/reports/tr18/#General_Category_Property
      if (/^[\P{L}]$/u.test(shortcut[shortcut.length - 1])) {
        const shortShortcut = shortcut.substr(0, shortcut.length - 1);
        trigger = trigger.substr(0, trigger.length - 1);

        if (activeShortcuts[shortShortcut]) {
          const replacements = activeShortcuts[shortShortcut];
          for (let i = 0; i < replacements.length; i++) {
            const group = replacements[i].group;
            if (group && group.data.options && group.data.options.trigger === 'standalone') {
              replacement = replacements[i];
              appendage = shortcut[shortcut.length - 1];
              break;
            }
          }
        }
      }
    }
  } else if ('snippetId' in msg) {
    replacement = sync.getSnippetById(msg.snippetId);
  } else if (IS_AI_BLAZE_DESKTOP) {
    const uInt8Delta = new Uint8Array(msg.details.delta);
    const shortcut = msg.details.defaultShortcut;
    delete msg.details.defaultShortcut;

    /** @type {Partial<SnippetObjectType>} */
    const partialData = {
      shortcut,
      ...msg.details,
      delta: uInt8Delta,
    };

    const groupId = '_AI_SNIPPET_CHAT_GROUP', snippetId = msg.details.snippetId;

    /** @type {SnippetObjectType} */
    const data = {
      ...partialData, id: snippetId, group_id: groupId,
    };

    replacement = {
      shortcut,
      id: snippetId,
      group_id: groupId,
      // @ts-ignore
      addonOptions: { },
      data,
      group: {
        deleting: false,
        unSubscribe: null,
        isAddon: () => false,
        namespace: '',
        snippets: [],
        updateData: () => {},
        data: {
          id: groupId,
        },
        id: groupId,
        loading: false,
        stub: false,
      }
    };
  }
  return [replacement, trigger, appendage];
}

function orderedGroups() {
  const activeGroups = sync.getGroupIds({
    order: true
  });

  return activeGroups.map(id => sync.groups[id]);
};

/**
 * @param {string} msg 
 * @param {{ isBlazeDashboard: boolean, remainingSnippets: number, maxSnippets: number }} obj
 */
function proWarning(msg, { isBlazeDashboard, remainingSnippets, maxSnippets }) {
  if (!maxSnippets) {
    // If there is no max-snippet, don't show any error
    return;
  }

  if (isBlazeDashboard) {
    // Don't count this usage against our trial in the dashboard
    notifyClient('Trying a Text Blaze Pro Snippet - You can try it out in the dashboard.', true);
    return; // allow the insertion to complete
  }
  
  if (remainingSnippets <= 0) {
    log({
      category: 'Purchase',
      action: 'Pro Trial Completion Blocked',
      label: {
        source: desktopType
      }
    });

    notifyClient('Out of Text Blaze Pro Trials Today. You used all of your Pro trial snippets for the day. Get Pro for unlimited snippets.', true);
    return true; // do not complete insertion
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useFeature('pro_snippets');
    remainingSnippets--;
    log({
      category: 'Purchase',
      action: 'Pro Trial Completion Warning',
      label: {
        source: desktopType
      }
    });
    notifyClient(`${remainingSnippets}/${maxSnippets} Pro Trial Snippets Left Today. ` + msg + ' Get Text Blaze Pro to unlock these features.', true);
  }
}

/**
 * @typedef {{ replacement: import('./snippet_processor/DownstreamProcess').ReplacementPart[], textString: string, htmlString: string, shortcut: string, snippetType: 'text'|'html', snippetId: string, featureUsage: import('./snippet_processor/DownstreamProcess').ProUsageReturnType, name: string, fbToken?: string, insertionType: InsertionType }} ReplacementData
 */

/**
 * @typedef {Object} ShortcutMessage
 * @property {string} shortcut
 * @property {string=} snippetId
 * @property {InsertionType=} insertionType
 * @property {SnippetTextInsertionDetails=} details
 * 
 * @typedef {Object} ReplacementType
 * @property {{'html': string, 'text': string}} replacement
 * @property {string} shortcut
 * @property {string} snippetType
 * @property {string} group
 * @property {string} group_id
 * @property {string} id
 * @property {string} delta
 * 
 * @typedef {Object} CheckForReplacementData 
 * @property {ShortcutMessage} msg
 * @property {object} sender
 * @property {string} owner
 * @property {string} application
 * @property {number} windowHandle
 * @property {boolean} tryMeBoxActive
 * @property {boolean} [isAIBlazeActive] 
 * @property {LogLabelsType=} logLabels
 * 
 * @typedef {Record<string, string | boolean>} LogLabelsType
 */

/**
 * @param {CheckForReplacementData} data 
 * @param {Function} callback 
 */
async function handleReplacement({ msg, sender, application, windowHandle, tryMeBoxActive, isAIBlazeActive, logLabels }, callback) {
  const [replacement, trigger, appendage] = getReplacement(msg);
  const insertionType = msg.insertionType;
  if (!replacement) {
    return callback(null);
  }
  let replacementObject = replacement.data;
  const isAISnippet = !!replacementObject.options?.is_ai;
  const isSidebarFreechat = replacement.id === '_AI_SNIPPET_CHAT_SNIPPET';
  if (isAIBlazeActive && isAISnippet) {
    return callback(null);
  }
  playSnippetSound();

  const userState = sync.getUserState();
  const snippetId = replacement.id;
  const groupId = replacement.group_id;
  const group = replacement.group;
  let IS_BLAZE_DASHBOARD = tryMeBoxActive;
  let proWarningShown = false;
  const shortcutToClear = 'snippetId' in msg ? '' : replacementObject.shortcut + appendage;
  // Check if this runs into Pro-Limitations
  // Note if they are Pro or org, we let them violate the limitations as we don't
  // want to break anything, the UI enforces the limitations though.
  const maxSnippets = limitationsState(getState()).MAX_PRO_SNIPPETS_PER_DAY;
  const usageProSnippets = await usageCount('pro_snippets');
  const remainingSnippets = maxSnippets - usageProSnippets;
  /** @type {Parameters<typeof proWarning>[1]} */
  const proWarningStateArg = { isBlazeDashboard: IS_BLAZE_DASHBOARD, remainingSnippets, maxSnippets };

  if (!isPro()) {
    const limitations = limitationsState(getState());
    const activeGroups = orderedGroups();
    // Only include groups the user has specifically added in max_group calculations
    const groupIndex = activeGroups.filter((x) => x.id in userState.groups).map((x) => x.id).indexOf(groupId);
    if (limitations.MAX_GROUPS && limitations.CAN_UPGRADE_MAX_GROUPS) {
      if (groupIndex >= limitations.MAX_GROUPS) {
        if (proWarning('This snippet\'s folder uses Pro features.', proWarningStateArg)) {
          return callback(null);
        }
        proWarningShown = true;
      }
    }

    if (!proWarningShown) {
      if (limitations.MAX_SNIPPETS_PER_GROUP && limitations.CAN_UPGRADE_MAX_SNIPPETS_PER_GROUP) {
        const childrenSnippets = group.snippets;
        const maxSnippetsPerGroup = limitations.MAX_SNIPPETS_PER_GROUP;
        if (childrenSnippets.length > maxSnippetsPerGroup) {
          const snippetIndex = childrenSnippets.map((x) => x.id).indexOf(snippetId);
          if (snippetIndex >= maxSnippetsPerGroup) {
            if (proWarning('This snippet\'s folder uses Pro features.', proWarningStateArg)) {
              return callback(null);
            }
            proWarningShown = true;
          }
        }
      }
    }

    if (!proWarningShown) {
      if (limitations.MAX_SNIPPETS && limitations.CAN_UPGRADE_MAX_SNIPPETS) {
        // if it's a team or org group (not in user data), we allow all snippets
        if (userState.groups[group.id]) {
          const maxSnippets = limitations.MAX_SNIPPETS;
          let snippetCount = 0;
          for (const counterGroup of activeGroups) {
            if (counterGroup === group) {
              snippetCount += counterGroup.snippets.map((x) => x.id).indexOf(snippetId) + 1;
              break;
            } else {
              snippetCount += counterGroup.snippets.length;
            }
          }

          if (snippetCount > maxSnippets) {
            if (proWarning('This snippet uses Pro features.', proWarningStateArg)) {
              return callback(null);
            }
            proWarningShown = true;
          }
        }
      }
    }
  }
  if (getOrgPref('xSharingDisabled')) {
    if (userState.org && userState.org.id !== group.data.associated_org_id) {
      notifyClient('Snippet is not associated with your organization. Contact your Text Blaze administrator.');
      return callback(null);
    }
  }

  const hostName = application || '';

  const { currentUser } = getAuth();
  if (!currentUser) {
    return callback(null);
  }

  let connectedSettings = getConnectedConfigOptions(userState, group.data);
  
  /** @type {import('./snippet_processor/DownstreamProcess').ConfigDefType} */
  let configDef = {
    domain: hostName,
    windowId: sender.tab.windowId,
    tabId: sender.tab.id,
    frameId: sender.frameId,
    quickentry: replacementObject.options.quick_entry || false,
    date: new Date(),
    locale: userState.locale,
    user: getUserMemberData(sync, getState()),
    snippet: {
      id: replacementObject.id,
      shortcut: replacementObject.shortcut,
      trigger: trigger,
      folderid: groupId
    },
    randomSeed: Math.random(),
    addons: sync.activeAddons(),
    findSnippet: (shortcut) => {
      let snippets = sync.getSnippetsByShortcut(shortcut);
      if (snippets && snippets.length) {
        return {
          delta: snippets[0].data.delta
        };
      }
    },
    commandWhitelist: getOrgPref('shouldWhitelistCommands') && (getOrgPref('commandWhitelist') || []),
    connectedSettings,
    typedShortcutText: msg.shortcut,
    insertionType,
    usedCommandsWhitelist: undefined,
    usedSiteSelectors: undefined,
    usedSiteSelectorData: undefined,
    usedLambdaWhitelist: undefined,
    usedSiteTabSelections: undefined,
    editorData: undefined,
    needsTabSelectInSiteCommand: false,
    showNotification,
    isOrg: isOrg()
  };

  const result = await evaluateDeltaUsingConfigDef(Array.from(replacementObject.delta), configDef, makeConfig, makeReplacement);
  if (result.success === false) {
    const defaultMessage = 'Error in Snippet - ' + replacementObject.shortcut;
    notifyClient(result.title ? result.message : defaultMessage);
    return callback(null);
  }
  const { featureUsage, snippetType, delta, isUsingFormFeatures, replacementRes, } = result;

  if (isAISnippet) {
    // Run the prioritization after a delay so it doesn't impact snippet processing
    setTimeout(() => {
      updatePrioritizationOfSnippets(snippetId, hostName);
    }, 2000);

    const hostnameAccepted = !!replacementObject.options?.include_page_context;
    pageContentAccepted = isSidebarFreechat || hostnameAccepted;

    log({
      category: 'Desktop',
      action: 'AI Snippet Launch',
      label: {
        hostnameAccepted,
        isUsingFormFeatures,
        source: desktopType
      }
    }, { snippet_id: snippetId, group_id: groupId });

    const formId = await storeCleanedNextFormData(sender.tab.windowId, sender.tab.id, sender.frameId, delta, configDef, snippetType, replacementObject.name, replacementObject.shortcut, replacementObject.id, replacementObject.group_id, appendage, featureUsage, IS_BLAZE_DASHBOARD, maxSnippets, usageProSnippets, logLabels, shortcutToClear, insertionType);

    /** @type {'polish'|'write'|'chat'} */
    let aiActionToSend = null; // leaving it null will classify the snippet
    if (!isSidebarFreechat) {
      const aiActionData = replacementObject.options.ai_action,
        aiActionUpdatedAt = aiActionData?.updated_at / 1000,
        snippetUpdatedAt = replacementObject.updated_at?.seconds,
        // Take 2 seconds as threshold
        isStaleActionUpdate = !snippetUpdatedAt || !aiActionUpdatedAt || aiActionUpdatedAt < snippetUpdatedAt - 2,
        userChoiceActionData = aiActionData?.action;
      if (!isStaleActionUpdate) {
        aiActionToSend = userChoiceActionData;
      }
    }
    nextFormData.aiData = {
      hostnameAccepted,
      rawDelta: Array.from(replacementObject.delta),
      aiAction: aiActionToSend,
    };

    // We clear the shortcut on launching the AI snippet
    // so we reset this as we don't need to clear on submit
    nextFormData.typedShortcutText = '';

    return callback({
      formId,
      insertionType,
      aiData: {
        shortcutToClear: shortcutToClear,
        windowHandle
      },
    });
  }

  if (!isUsingFormFeatures) {
    if (!isPro() && !proWarningShown) {
      if (featureUsage.proLabels && featureUsage.proLabels.length) {
        if (proWarning('This snippet uses Pro features.', proWarningStateArg)) {
          return callback(null);
        }
      }
    }

    let replacementObj = replacementRes.replacement;
    const { length } = replacementRes;
    logReplacement({ length, snippetId: replacementObject.id, groupId: replacementObject.group_id, featureUsage, insertionType, logLabels });

    // If we have an appendage, add it after everything else
    if (appendage) {
      replacementObj.push({
        type: 'string',
        textStrArr: [appendage]
      });
    }

    const { textString, htmlString } = convertReplacementPartsToText(replacementObj);
    /** @type {ReplacementData} */
    const res = {
      replacement: replacementObj,
      shortcut: replacementObject.shortcut + appendage,
      insertionType,
      snippetType,
      snippetId: replacementObject.id,
      name: replacementObject.name,
      featureUsage,
      textString,
      htmlString
    };

    if (['dbselect', 'dbinsert', 'dbupdate', 'dbdelete'].some(val => featureUsage.features?.COMMANDS?.includes(val))) {
      // It can fail due to a network issue which leads to db* commands failing with the "Could not run SQL" error. 
      // The reason for using try-catch is to avoid blocking snippet insertion.
      try {
        res.fbToken = await getIdToken(getAuth().currentUser);
      } catch {}
    }

    return callback(res);
  } else {
    let formId = await storeCleanedNextFormData(sender.tab.windowId, sender.tab.id, sender.frameId, delta, configDef, snippetType, replacementObject.name, replacementObject.shortcut, replacementObject.id, replacementObject.group_id, appendage, featureUsage, IS_BLAZE_DASHBOARD, maxSnippets, usageProSnippets, logLabels);
    showFormWindow(windowHandle);
    return callback({
      formId
    });
  }
}

/**
 * @param {string} name
 * @param {object} nonOrgDefault
 * @param {object} orgDefault
 * 
 * @return {object}
 */
function getOrgPref(name, nonOrgDefault = undefined, orgDefault = undefined) {
  return orgPref(getState(), name, nonOrgDefault, orgDefault);
}

/**
 * @typedef {{ userState: object, orgState: { org: object }, config: object }} StateType
 * @return {StateType}
 */
function getState() {
  return {
    userState: store.getState().userState,
    orgState: {
      org: sync.org && sync.org.data
    },
    config: store.getState().config
  };
};


const makeConfig = makeConfigMaker('DESKTOP', handleRemoteCommand, getClipboard, getSiteData);
const makeReplacement = makeReplacementMaker(processSideChannelItems);

/**
 * @param {number} windowId
 * @param {number} tabId
 * @param {number} frameId
 * @param {object} delta 
 * @param {object} configDef 
 * @param {'html'|'text'} snippetType 
 * @param {string} name 
 * @param {string} shortcut 
 * @param {string} snippetId 
 * @param {string} groupId 
 * @param {string} appendage 
 * @param {object} featureUsage 
 * @param {boolean} isBlazeDashboard 
 * @param {number} maxSnippets
 * @param {number} usageProSnippets
 * @param {LogLabelsType} logLabels
 * @param {string} [typedShortcutText]
 * @param {InsertionType} [insertionType]
 */
async function storeCleanedNextFormData(windowId, tabId, frameId, delta, configDef, snippetType, name, shortcut, snippetId, groupId, appendage, featureUsage, isBlazeDashboard, maxSnippets, usageProSnippets, logLabels, typedShortcutText, insertionType = 'shortcut') {
  formIdCount++;

  log({
    category: 'Desktop',
    action: 'Show form window',
    label: {
      source: desktopType,
      ...logLabels
    }
  }, {
    snippet_id: snippetId,
    group_id: groupId
  });

  const formAddons = getCleanedAddons(sync.activeAddons());

  const isUsingProFeatures = !!featureUsage.features['FORM'] || !!featureUsage.features['REMOTE_LOAD'],
    userIsPro = isPro(),
    userIsOrg = isOrg(),
    message = (!userIsPro && isBlazeDashboard && maxSnippets) ? 'Forms are a Text Blaze Pro feature. You can try them out in the dashboard.' : null,
    maxFreeProSnippets = !userIsPro && !isBlazeDashboard && maxSnippets;

  nextFormData = {
    isUsingProFeatures,
    formId: formIdCount.toString(),
    windowId,
    tabId,
    frameId,
    snippetId,
    groupId,
    delta,
    addons: formAddons,
    configDef,
    name,
    snippetType,
    shortcut,
    isPro: userIsPro,
    isOrg: userIsOrg,
    // don't count/prevent snippets if Pro or on the Blaze dashboard site
    maxFreeProSnippets,
    countUsage: !isBlazeDashboard,
    message,
    appendage,
    featureUsage,
    typedShortcutText,
    insertionType,
    userId: uid(),
    isOmnibox: false,
    locale: sync.getUserState().locale,
    usageProSnippets,
  };

  if (['dbselect', 'dbinsert', 'dbupdate', 'dbdelete'].some(val => featureUsage.features.COMMANDS.includes(val))) {
    // It can fail due to a network issue which leads to db* commands failing with the "Could not run SQL" error. 
    // The reason for using try-catch is to avoid blocking snippet insertion.
    try {
      nextFormData['fbToken'] = await getIdToken(getAuth().currentUser);
    } catch {}
  }

  // Functions cannot be structure cloned, and anyway,
  // this function is re-written at the destination
  deleteConfigDefCircularKeys(nextFormData.configDef);

  return formIdCount;
}

/**
 * 
 * @param {number} windowHandle 
 */
function showFormWindow(windowHandle) {
  sendMessageToClient({
    type: 'form-data',
    data: { nextFormData, locale: sync.getUserState().locale, windowHandle }
  });
}

/**
 * @typedef {'shortcut'|'assistant'|'sidebar'} InsertionType   
 */

/**
 * @typedef {Object} LogReplacementType
 * @property {number} length 
 * @property {string} snippetId 
 * @property {string} groupId 
 * @property {object} featureUsage
 * @property {InsertionType} [insertionType]
 * @property {LogLabelsType} [logLabels] 
 * @param {LogReplacementType} param0 
 */
function logReplacement({ length, snippetId, groupId, featureUsage, insertionType, logLabels }) {
  const label = { length, features: featureUsage.features.COMMANDS, source: desktopType, insertionType: insertionType || 'shortcut', ...logLabels, };
  addBreadcrumb({
    message: 'Snippet triggered',
    data: { snippetId, groupId }
  });
  log({
    category: 'Desktop',
    action: 'Trigger replacement',
    label,
    value: length
  }, {
    snippet_id: snippetId,
    group_id: groupId
  });
}

/**
 * 
 * @param {string} application - For prioritization of AI prompts based on focused application
 * @returns 
 */
function getAppState(application) {
  const groups = orderedGroups();
  let snippets = [];
  for (const group of groups) {
    snippets = snippets.concat(group.snippets);
  }
  snippets = snippets.map(x => x.data);
  /** @type {Pick<GroupObjectType, "id" | "name" | "options" | "connected">[]} */
  let groupsCleaned = groups.map(x => ({
    id: x.data.id,
    name: x.data.name,
    options: x.data.options,
    connected: x.data.connected,
  }));
  if (IS_AI_BLAZE_DESKTOP) {
    ({ snippets, groups: groupsCleaned, } = getPrioritizedSnippets(snippets, groupsCleaned, application));
  }

  // Delete firebaseUser because it is not required by micro app
  // and it fails structured clone on IPC
  const userData = Object.assign({}, sync.getUserState());
  delete userData['firebaseUser'];
  const assistantData = {
    userData,
    org: sync.org && sync.org.data,
    groups: groupsCleaned,
    snippets,
    config: store.getState().config
  };

  return JSON.stringify(assistantData);
}

/**
 * 
 * @param {string=} application - For prioritization of AI prompts based on focused application
 */
function sendAssistantDataToApp(application) {
  sendMessageToClient({
    type: 'send-assistant-data',
    data: getAppState(application)
  });
}

/**
 * 
 * @param {any} data 
 */
function showNotification(data) {
  sendMessageToClient({
    type: 'show-message',
    data
  });
}