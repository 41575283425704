import { DataContainer, Environment } from './DataContainer';
import ParseNode from './ParseNode';
import { domToStream, compressStream, postProcessDom, splitDom, createDom, fillDom } from './SnippetProcessor';
import { callFn, objectToList, runErrorBlock, runFinishBlock } from './Equation';
import { getOrgCommandsWhitelist } from '../flags';
import { userMemberData } from '../engine_utilities';
import { restoreAddonFromCleaned, selectorFn } from './DownstreamProcess';
import { proUsage } from '../components/Version/limitations';
import { decompressDelta } from '../delta_proto/DeltaProto';
import { getDataFromClient } from '../desktop_shared_utils';

/**
 * @typedef {ReturnType<makeConfigMaker>} ConfigMakerType
 * 
 * @param {'EXTENSION'|'DESKTOP'} application
 * @param {import('./DownstreamProcess').NativeRemoteCommandFn} runRemoteCommand 
 * @param {import('./DownstreamProcess').NativeGetClipboardFn} getClipboard 
 * @param {import('./DownstreamProcess').NativeGetAllTabsDataFn} getSiteData
 * @returns 
 */
export function makeConfigMaker(application, runRemoteCommand, getClipboard, getSiteData) {
  /**
   * @param {import('./DownstreamProcess').ConfigDefType} configDef
   * @param {{ snippetType?: 'html'|'text' }} [typeObj]
   * 
   * @return {import("./DataContainer").Config}
   */
  function makeConfig(configDef, typeObj = {}) {
    
    /** @type {import("./DataContainer").Config} */
    const config = {
      typeObj: typeObj,
      domain: configDef.domain,
      application,
      usedCommandsWhitelist: configDef.usedCommandsWhitelist,
      usedLambdaWhitelist: configDef.usedLambdaWhitelist,
      usedSiteSelectors: configDef.usedSiteSelectors,
      usedSiteSelectorData: configDef.usedSiteSelectorData,
      usedSiteTabSelections: configDef.usedSiteTabSelections,
      getDataFromAllMatchingTabs: getSiteData,
      clipboard: async (hint) => {
        const type = hint || typeObj.snippetType;
        const val = await getClipboard();
        
        // We want to get the requested type, but we fallback correctly
        // if nothing is available on the specific type.
        let getText = type === 'text' && (typeof val.text === 'string');

        if (!getText) {
          if (typeof val.html !== 'string' || val.html === '') {
            // if we want html but there is no html, we return text
            getText = true;
          }
        }
        if (getText) {
          // if text is undefined, make sure to return ''
          return new ParseNode('expand', 'text', val.text || '');
        } else {
          return new ParseNode('expand', 'html', val.html);
        }
      },
      locale: configDef.locale,
      user: configDef.user,
      snippet: configDef.snippet,
      randomSeed: configDef.randomSeed,
      date: configDef.date,
      doCommandsRan: {},
      quickentry: configDef.quickentry,
      findSnippet: configDef.findSnippet,
      addons: configDef.addons,
      needsTabSelectInSiteCommand: configDef.needsTabSelectInSiteCommand,
      selectorFn: function (item) {
        return selectorFn(this.usedSiteTabSelections, item);
      },
      remoteFn: async (node) => {
        const res = await runRemoteCommand(node.info);
        // We have replaced text with raw where objectToList is not already applied, but older extension can still have response with having text
        if (res?.raw) {
          res.text = objectToList(res.raw);
          delete res.raw;
        }
        return res;
      },
      commandWhitelist: configDef.commandWhitelist,
      editorData: configDef.editorData,
      showNotification: configDef.showNotification,
      onFormDirty: configDef.onFormDirty
    };
    Object.assign(config, configDef.connectedSettings.config);

    return config;
  };

  return makeConfig;
}

/**
 * An object representing CSS styles provided by desktop apps identified from the point of insertion
 *
 * @typedef {Object} ContextualStylesType
 * @property {string} [font-family] - Specifies the font family for the text.
 * @property {string} [font-style] - Defines the style of the font, such as italic.
 * @property {string} [font-weight] - Sets the weight (thickness) of the font. Accepts numeric values ranging from 100 to 900.
 */

/**
 * @typedef {ReturnType<makeReplacementMaker>} ReplacementMakerType
 * 
 * @param {import('./DownstreamProcess').NativeSideChannelFn} processSideChannelItems 
 */
export function makeReplacementMaker(processSideChannelItems) {
  /**
   * @param {ParseNode} dom 
   * @param {'html'|'text'} snippetType
   * @param {Environment} env
   * @param {import('./DownstreamProcess').NextFormDataType['configDef']} configDef
   * @param {ContextualStylesType} [contextualStyles]
   */
  function makeReplacement(dom, snippetType, env, configDef, contextualStyles = {}) {
    deleteConfigDefCircularKeys(configDef);
    const postProcessed = postProcessDom(dom);
    if (postProcessed.sideChannel?.length > 0) {
      /** @type {Object<string, Object<string, any>>} */
      const store = {};
      for (const key in env.config.store) {
        if (key === '_cache') {
          continue;
        }
        store[key] = env.config.store[key].flattenData();
      }
      processSideChannelItems(postProcessed.sideChannel, { store, formConfig: configDef });
    }

    const parts = splitDom(postProcessed);
    let length = 0;


    /**
     * @param {any[]} items 
     * 
     * @return {(string|{tag:string, type: string})[]}
     */
    function simplifyStream(items) {
      return items.map(item => {
        if (typeof item === 'string') {
          return item;
        } else {
          // for {cursor}
          return {
            tag: item.tag,
            type: item.type
          };
        }
      }).filter((item) => {
        // remove empty string
        if (typeof item === 'string') {
          if (item === '') {
            return false;
          }
        }
        return true;
      });
    }

    const replacement = parts.map(part => {
      if (part.type === 'root') {
        /** @type {string[]} */
        let htmlStrArr;
        if (snippetType === 'html') {
          const htmlParts = simplifyStream(domToStream(part, 'html', undefined, contextualStyles));
          htmlStrArr = compressStream(htmlParts);
        }
        const textParts = simplifyStream(domToStream(part, 'text'));
        /** @type {string[]} */
        const textStrArr = compressStream(textParts);
        length += textStrArr.reduce((prev, current) => prev + (typeof current === 'string' ? current.length : 0), 0);
        /** @type {import('./DownstreamProcess').BasicReplacement} */
        const ret = {
          type: 'string',
          htmlStrArr,
          textStrArr
        };
        return /** @type {import('./DownstreamProcess').ReplacementPart} */ (ret);
      } else {
        part.parent = null;
        return /** @type {import('./DownstreamProcess').ReplacementPart} */ (part);
      }
    }).filter(x => {
      // Filter out empty replacement parts
      if (x.type === 'string') {
        return x.textStrArr.length > 0 || x.htmlStrArr?.length > 0;
      }
      return true;
    });

    // If first insertion is not a string, we need
    // to manually insert an empty string so that
    // the content script will clear the shortcut
    if (replacement[0]?.type !== 'string') {
      replacement.unshift({
        type: 'string',
        htmlStrArr: [],
        textStrArr: []
      });
    }

    return {
      replacement,
      length
    };
  };

  return makeReplacement;
}

/**
 * Used by the downstream process when it needs to call 
 * a function with specific arguments
 * @param {Pick<import('@store').RootState, 'userState'|'orgState'>} userData
 * @param {import('./ParseNode').default} lambda
 * @param {[string, number]} args
 * @returns {Promise<string>}
 */
export function callCompletedFn(userData, lambda, args) {
  /** @type {string[]} */
  const orgCommandWhitelist = getOrgCommandsWhitelist(userData);
  const ourCommandWhitelist = ['TIME', '=', 'USER'];
  const commandWhitelist = ourCommandWhitelist.filter(x => orgCommandWhitelist === null || orgCommandWhitelist.includes(x));

  const env = new Environment({});
  env.config.user = userMemberData(userData.userState.email, userData.orgState.org?.member_fields, userData.userState.member_fields_data);
  env.config.commandWhitelist = commandWhitelist;
  env.config.stage = 'preview';
  
  return callFn(lambda, args, env).catch(() => {
    return 'The completed setting does not contain a valid results handler function';
  });
}

/**
 * @param {ReturnType<makeConfigMaker>} makeConfig
 * @param {import('./DownstreamProcess').NativeRemoteBlockDataType} blockData
 * @param {(notificationData: { message: string, title: string }) => void} showNotification
 */
async function getBlockEnvForDownstream(makeConfig, { formConfig: configDef, store }, showNotification) {
  configDef.showNotification = showNotification;
  // Note: addons and imports are not supported in this context

  const env = new Environment({}, Object.assign(makeConfig(configDef), {
    stage: 'preview',
  }));
  env.config.store = {};
  for (const key in store) {
    const container = new DataContainer(store[key]);
    await container.ready;
    env.config.store[key] = container;
  }
  env.data = env.config.store['root'];
  
  return env;
}


/**
 * 
 * This function deletes functions that cannot be cloned, and
 * puts them in a separate object, so they can be later restored if needed
 * @param {import('./DownstreamProcess').ConfigDefType} data 
 */
export function deleteConfigDefCircularKeys(data) {
  /** @type {(keyof import('./DownstreamProcess').ConfigDefType)[]} */
  const keys = ['findSnippet', 'showNotification', 'onFormDirty', 'addons'];

  for (const key of keys) {
    if (key in data) {
      delete data[key];
    }
  }
}

/**
 * @param {import('./DownstreamProcess').ConfigDefType} configDef
 * @param {Record<string, import('./DownstreamProcess').CleanedAddonType>} cleanedAddons 
 * @param {(notificationData: { message: string; title: string; }) => void} showNotification 
 * @param {import('./DownstreamProcess').NativeFindSnippetFn} findSnippet 
 * @param {(isDirty: boolean) => void} onFormDirty 
 */
export function restoreConfigDefFromCleaned(configDef, cleanedAddons, showNotification, findSnippet, onFormDirty) {
  if (showNotification) {
    configDef.showNotification = showNotification;
  }
  if (onFormDirty) {
    configDef.onFormDirty = onFormDirty;
  }
  configDef.findSnippet = findSnippet;
  configDef.addons = restoreAddonFromCleaned(cleanedAddons);
}

/**
 * @param {string} command
 * @param {import('./ParseNode').default} finishBlock 
 * @param {string} text 
 * @param {'success'|number} status 
 * @param {import('./DownstreamProcess').NativeRemoteBlockDataType} blockData 
 * @param {ReturnType<makeConfigMaker>} makeConfig 
 * @param {(notificationData: { message: string, title: string }) => void} showNotification 
 */
export async function runFinishBlockDownstream(command, finishBlock, text, status, blockData, makeConfig, showNotification) {
  const env = await getBlockEnvForDownstream(makeConfig, blockData, showNotification);
  try {
    await runFinishBlock(command, finishBlock, env, { data: text, status });
  } catch (e) {
    env.config.showNotification({ title: 'Failed to run finish block', message: e.message });
  }
}

/**
 * @param {string} command
 * @param {import('./ParseNode').default} errorBlock
 * @param {string} err
 * @param {'error'|number} status 
 * @param {import('./DownstreamProcess').NativeRemoteBlockDataType} blockData 
 * @param {ReturnType<makeConfigMaker>} makeConfig 
 * @param {(notificationData: { message: string, title: string }) => void} showNotification 
 */
export async function runErrorBlockDownstream(command, errorBlock, err, status, blockData, makeConfig, showNotification) {
  const env = await getBlockEnvForDownstream(makeConfig, blockData, showNotification);
  try {
    await runErrorBlock(command, errorBlock, env, err, status || 'error');
  } catch (e) {
    env.config.showNotification({ title: 'Failed to run error block', message: e.message });
  }
}

/**
 * @typedef {Pick<import('./DownstreamProcess').ConfigDefType, 'usedCommandsWhitelist'|'usedLambdaWhitelist'|'usedSiteSelectors'|'usedSiteSelectorData'|'needsTabSelectInSiteCommand'|'usedSiteTabSelections'>} ConfigDefSelectedProps
 * 
 * @typedef {{ success: false, message: string, title?: string, } | { success: true, featureUsage: import('./DownstreamProcess').ProUsageReturnType, snippetType: 'html'|'text', delta: DeltaType, configDefProps: ConfigDefSelectedProps, isUsingFormFeatures: boolean, replacementRes: ReturnType<ReturnType<typeof makeReplacementMaker>> }} EvaluatorProcessedReplacementType
 * 
 * @param {number[]} deltaInput
 * @param {import('./DownstreamProcess').ConfigDefType} configDef
 * @param {ReturnType<typeof makeConfigMaker>} makeConfig
 * @param {ReturnType<typeof makeReplacementMaker>} makeReplacement
 * @returns {Promise<EvaluatorProcessedReplacementType>}
 */
export async function evaluateDeltaUsingConfigDef(deltaInput, configDef, makeConfig, makeReplacement) {
  const connectedSettings = configDef.connectedSettings;

  const delta = decompressDelta(new Uint8Array(deltaInput));
 
  const config = makeConfig(Object.assign({}, configDef));

  const env = new Environment({}, Object.assign({}, config, { stage: 'insertion' }));

  const dom = await createDom(JSON.parse(JSON.stringify(delta)), env);
  const featureUsage = await proUsage(dom, env);

  const usedCommandsWhitelist = featureUsage.features.COMMANDS.map(x => x.toUpperCase());
  const usedLambdaWhitelist = featureUsage.features.LAMBDAS.slice();
  const usedSiteSelectors = featureUsage.features.SITE_SELECTORS;
  const { selectorData: usedSiteSelectorData, needsTabSelectInSiteCommand, usedSiteTabSelections, } = await env.config.getDataFromAllMatchingTabs(usedSiteSelectors, configDef.tabId, configDef.frameId);

  /** @type {ConfigDefSelectedProps} */
  const configDefProps = {
    usedCommandsWhitelist, usedLambdaWhitelist, usedSiteSelectorData, usedSiteSelectors, needsTabSelectInSiteCommand, usedSiteTabSelections,
  };

  for (const key of Object.keys(configDefProps)) {
    // for forms
    configDef[key] = configDefProps[key];
    // for non-forms
    env.config[key] = configDefProps[key];
  }

  /** @type {'html'|'text'} */
  const snippetType = featureUsage.features['STYLED'] ? 'html' : 'text';
  config.typeObj.snippetType = snippetType;

  // When invalidShare is set to true, we also set isConnected to false intentionally
  // even when the group may be connected (to avoid intentionally running any connected code)
  if (!connectedSettings.isConnected && connectedSettings.invalidShare) {
    if (featureUsage.features['CONNECTED']) {
      // Remote commands can only be run by the creator or someone in the org
      // associated with the group.
      return {
        success: false,
        title: 'Cannot Share Connected Snippets',
        message: 'You cannot use shared connected snippets outside Text Blaze Business.',
      };
    }
  }

  if (!configDef.isOrg && featureUsage.businessLabels && featureUsage.businessLabels.length) {
    return {
      success: false,
      title: 'Text Blaze Business Required',
      message: 'This snippet requires Text Blaze Business features.',
    };
  }

  const isUsingFormFeatures = featureUsage.features['FORM'] || featureUsage.features['REMOTE_LOAD'] || needsTabSelectInSiteCommand || featureUsage.features['BUTTON'] || featureUsage.features['RUN'];

  /** @type {ReturnType<ReturnType<typeof makeReplacementMaker>>} */
  let replacementRes;

  if (!isUsingFormFeatures) {
    const domFilled = await fillDom(dom, env);
    const blocker = domFilled.find(node => node.type === 'error' && node.info.blocking);
    if (blocker) {
      return { success: false, message: blocker.info.message, };
    }
    const contextualStyles = await getDataFromClient({ type: 'get-context' }) || {};
    replacementRes = makeReplacement(domFilled, snippetType, env, configDef, contextualStyles);
  }

  return { success: true, configDefProps, snippetType, featureUsage, delta, isUsingFormFeatures, replacementRes, };
}