let stripeKey, appPostfix, authDomain, firebaseApiKey;
if (import.meta.env.VITE_APP_CONFIG === 'stage' && !import.meta.env.PROD) {
  // STAGING CONFIG:
  stripeKey = 'pk_test_DUfs5nBOy6epOconDT3fAKd5';
  appPostfix = '-stage';
  authDomain = 'blaze-today-stage.firebaseapp.com';
  firebaseApiKey = 'AIzaSyBdNyg2sVmPmdf040tv6ZK9_R0ZA6kaxl0';
} else {
  // PRODUCTION CONFIG:
  stripeKey = 'pk_live_DoCFizxxSwPHA7dPdM8Gn7d3';
  appPostfix = '';
  authDomain = 'login.blaze.today';
  firebaseApiKey = 'AIzaSyA0_OH1h028rsgkdgJIEjVOSe8W9K3ZstQ';
}

/**
 * @type {import("firebase/app").FirebaseOptions}
 */
const appConfig = {
  apiKey: firebaseApiKey,
  authDomain: authDomain,
  databaseURL: `https://blaze-today${appPostfix}.firebaseio.com`,
  storageBucket: `blaze-today${appPostfix}.appspot.com`,
  messagingSenderId: '435466840917',
  projectId: `blaze-today${appPostfix}`
};

/** @type {string} */
export const STRIPE_KEY = stripeKey;
/** @type {string} */
export const APP_POSTFIX = appPostfix;
export const APP_CONFIG = appConfig;