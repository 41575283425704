import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { checkPayingPro } from './flags.js';
import { userMemberData } from './userMemberData.js';
import { readFromLocalStorage } from './desktop_utilities.js';
import { getDataFromClient } from './desktop_shared_utils.js';
import { resizeImage } from './image_shared_utils.js';
import { promiseDelay } from './standalone_utilities.js';

/** @type {Promise<number[]>} */
let capturedImagePromise = null;
/** @type {Promise<string>} */
let windowContentPromise = null;

/**
 * Gets the user's email if verified.
 * 
 * @return {string}
 */
function email() {
  return !!getAuth(getApp()).currentUser?.emailVerified ? getAuth(getApp()).currentUser.email : null;
};

export function isBlaze() {
  return email()?.endsWith('@blaze.today');
}

/**
 * @returns {import('../../../dashboard/src/js/components/PermissionList/utilities.js').UserInfo}
 */
export function getSelfUserInfo() {
  /** @type {import('firebase/auth').User} */
  const user = getAuth(getApp()).currentUser;
  return {
    email: user.email,
    name: user.displayName,
    imageUrl: user.photoURL,
    emailVerified: user.emailVerified,
    uid: user.uid
  };
}

/** 
 * @param {import('./store_platform.js').StateType} state 
 * @return {boolean}
 */
export function isPayingPro(state) {
  return checkPayingPro(state);
}

/**
 * 
 * @param {import('./Sync/Sync').default} sync 
 * @param {import('./store_platform.js').StateType} state 
 * @returns 
 */
export function getUserMemberData(sync, state) {
  return userMemberData(
    email(),
    state.orgState.org?.member_fields,
    sync.getUserState()?.member_fields_data
  );
}

/** @type {string} */
export const AI_SNIPPETS_MODEL_KEY = 'aiSnippetsModel';
/** @type {string} */
export const AI_CONFIG_KEY = 'aiLocalConfig';

export function getAIConfig() {
  return readFromLocalStorage(AI_CONFIG_KEY) || {};
}

export function getModelDetails() {
  // Leave the value as undefined if it is undefined, this allows us to have full control
  // over the default value of this property from the frontend
  /** @type {import('./components/AppEmbed/App.js').AIModelType} */
  const model = readFromLocalStorage(AI_SNIPPETS_MODEL_KEY) || undefined;
  return { model, };
}

function arrayToDataURL(image) {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext('2d');
  const imageData = new ImageData(new Uint8ClampedArray(image.data), image.width, image.height);
  ctx.putImageData(imageData, 0, 0);
  const dataURL = canvas.toDataURL();
  return dataURL;
}

/**
 * @param {boolean} pageContentAccepted 
 */
export async function getContextData(pageContentAccepted) {
  capturedImagePromise = null;
  windowContentPromise = null;
  if (pageContentAccepted) {
    const currentModel = getModelDetails().model;
    if (!currentModel || currentModel.supportsImage) {
      const resultImage = await getDataFromClient({ type: 'ai-chat', subType: 'get-screenshot' });
      // We can delay image processing, but the embed window should show up quickly
      capturedImagePromise = resultImage ? promiseDelay(0).then(() => {
        return resizeImage(arrayToDataURL(resultImage));
      }) : null;
    }
    windowContentPromise = getDataFromClient({ type: 'ai-chat', subType: 'get-window-text' });
  }
}

/**
 * @param {import('./snippet_processor/DownstreamProcess.js').ConfigDefType} configDef 
 * @returns {Promise<import('./components/AppEmbed/App.js').HostDataFromClient>}
 */
export async function getHostData(configDef) {
  try {
    let hostname = configDef.domain || null;

    const [pageContentData, resultImageSend] = await Promise.all([
      windowContentPromise,
      capturedImagePromise
    ]);

    const pageContent = pageContentData || null;

    /** @type {import('./components/AppEmbed/App').HostDataFromClient} */
    // @ts-ignore
    const dataToSend = { hostname, pageContent, selectedContent: null, rawCapturedImage: resultImageSend, rawImageType: 'webp', };
    return dataToSend;
  } catch (e) {
    return null;
  }
}