import { captureException } from '@sentry/browser';

/**
 * @param {ImageBitmap} imgBitmap 
 * @param {number} maxDimension 
 */
function makeCanvas(imgBitmap, maxDimension) {
  let width = imgBitmap.width, 
    height = imgBitmap.height;


  // If the width/height is already smaller than the target required, just use that
  // Otherwise scale the bare minimum to get to the desired size
  // In case both the dimensions need to be scaled, choose the lower scaling
  // so as to maintain the image aspect ratio
  const widthFraction = maxDimension >= width ? 1 : maxDimension / width;
  const heightFraction = maxDimension >= height ? 1 : maxDimension / height;
  const fractionScaling = Math.min(heightFraction, widthFraction);
  width = Math.round(width * fractionScaling); 
  height = Math.round(height * fractionScaling); 


  // @ts-ignore
  const canvas = new OffscreenCanvas(width, height);
  const ctx = canvas.getContext('2d');
  // High quality anti-aliasing
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high';
  ctx.drawImage(imgBitmap, 0, 0, width, height);

  return canvas;
}


/**
 * @param {string} imageSrc 
 * @returns {Promise<Number[]>}
 */
async function resizeImage(imageSrc) {
  /** @type {ImageBitmap} */
  let imgBitmap;
  try {
    // Convert b64 string to blob, which is then passed to createImageBitmap
    // https://stackoverflow.com/a/36183085/2181238
    const imgBlob = await fetch(imageSrc).then(res => res.blob());
    imgBitmap = await createImageBitmap(imgBlob);
  } catch (e) {
    captureException(e);
    return [];
  }

  let bigCanvas = makeCanvas(imgBitmap, 1200);
  let smallCanvas = makeCanvas(imgBitmap, 800);



  let options = await Promise.all([
    await bigCanvas.convertToBlob({
      type: 'image/webp',
      quality: 0.3,
    }),

    await bigCanvas.convertToBlob({
      type: 'image/webp',
      quality: 0.01,
    }),

    await smallCanvas.convertToBlob({
      type: 'image/webp',
      quality: 0.3,
    }),

    await smallCanvas.convertToBlob({
      type: 'image/webp',
      quality: 0.01,
    })
  ]);


  let deltas = options.map(option => Math.abs(32_000 - option.size));

  let bestImage = 0;
  for (let i = 1; i < options.length; i++) {
    if (deltas[i] < deltas[bestImage]) {
      bestImage = i;
    }
  }


  let blob = options[bestImage];

  const arrayBuffer  = await blob.arrayBuffer();

  return Array.from(new Uint8Array(arrayBuffer));
}


export { resizeImage };