import { clientSupportsFeature } from './desktop_shared_utils';
import { browser, } from './extension_utilities';
import { APP_TARGET, isAndroid, isElectronApp, isMacPlatform, isWinPlatform } from './raw_flags';

/**
 * @typedef {Partial<Record<'chrome'|'edge', { PROD: string, TEAM?: string, EA?: string, MV3?: string, }>>} ExtensionIDsListType
 */

// Keep in sync with extension_embed.js
/** @type {ExtensionIDsListType} */
const TEXT_BLAZE_EXTENSION_IDS = {
  chrome: {
    PROD: 'idgadaccgipmpannjkmfddolnnhmeklj',
    EA: 'amhmmkiplhcagoggialfgcnfkjklfpal',
    TEAM: 'phlneekiamnjipdchhikhjbdpmcieefo',
    MV3: 'looebmbfedhaljacdfgkepahikaaanbi'
  },
  edge: {
    PROD: 'fephhmmlanlhoiaphlodlhbmbnkmkckn',
  },
};

/** @type {ExtensionIDsListType} */
const AI_BLAZE_EXTENSION_IDS = {
  chrome: {
    PROD: 'cebmnlammjhancocbbnfcglifgdpfejc',
    TEAM: 'pinhobdbbhdjdfddonfmggpilhmpcfnm',
  },
};
const TEXT_BLAZE_EXTENSION_IDS_ARRAY = [...Object.values(TEXT_BLAZE_EXTENSION_IDS.chrome), ...Object.values(TEXT_BLAZE_EXTENSION_IDS.edge)];
const AI_BLAZE_EXTENSION_IDS_ARRAY = [...Object.values(AI_BLAZE_EXTENSION_IDS.chrome)];

// NOTE: This is the store from which extension was installed
/** @type {'chrome'|'edge'} */
export let EXTENSION_BROWSER_NAME = 'chrome';

/** @type {'text'|'ai'} whether we are connected to text blaze or ai blaze */
export const EXTENSION_TYPE = APP_TARGET === 'AI' ? 'ai' : 'text';

/** @type {string[]} */
let EXTENSION_IDS_ARRAY = APP_TARGET === 'AI' ? AI_BLAZE_EXTENSION_IDS_ARRAY : TEXT_BLAZE_EXTENSION_IDS_ARRAY;

function updateExtensionBrowserName() {
  return sendToExtension({ type: 'getMetadata' }).then((response) => {
    if (response?.browser) {
      EXTENSION_BROWSER_NAME = response.browser;
      EXTENSION_IDS_ARRAY = [response.id];
    }
  });
}

export function getStoreName() {
  if (isElectronApp()) {
    // We currently don’t support MAS
    return 'Microsoft Store';
  }
  return {
    chrome: 'Chrome Web Store',
    edge: 'Edge Add-ons Store'
  }[getCurrentBrowserName()];
}


export function getExtensionType() {
  return { edge: 'Edge Add-on', chrome: 'Chrome Extension' }[getCurrentBrowserName()];
}

/**
 * 
 * @returns {'chrome'|'edge'}
 */
export function getCurrentBrowserName() {
  /*
  Source: https://stackoverflow.com/questions/31721250/how-to-target-edge-browser-with-javascript
  User agents in Chrome and Edge:
  Chrome: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36'
  Edge: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36 Edg/126.0.0.0'
   */
  if (/Edg/.test(navigator.userAgent)) {
    return 'edge';
  }
  return 'chrome';
}

export function getProperCurrentBrowserName() {
  const browserName = getCurrentBrowserName();
  return browserName.charAt(0).toUpperCase() + browserName.slice(1);
}

/**
 * @param {{type: string} & Record<string, object>} data
 */
export async function sendToExtension(data) {
  // don't send anything to the extension if we are on app
  if (isAndroid() || isElectronApp()) {
    return;
  }

  // Nothing we can do if there is no runtime
  if (!browser() || !browser().runtime || !browser().runtime.sendMessage) {
    return undefined;
  }
  let res = await Promise.all(EXTENSION_IDS_ARRAY.map(id => {
    return new Promise((resolve) => {
      browser().runtime.sendMessage(id, data, undefined, (x) => {
        if (browser().runtime.lastError) {
          // ignore as extension does not exist
        }
        resolve(x);
      });
    });
  }));

  return res.find(x => x !== undefined);
}


/**
 * @return {Promise<object>}
 */
export async function getExtensionCredentials(data) {
  return sendToExtension({ type: 'get_credentials', data });
}


/**
 * Returns the version of the installed extension
 * @return {Promise<string>}
 */
export async function getVersion() {
  return sendToExtension({ type: 'get_version' });
}

if (typeof window !== 'undefined') {
  window['getVersion'] = getVersion;
  window['checkInstalled'] = checkInstalled;
}

/**
 * Return true if we are running on any platform app
 * @return {Promise<boolean>}
 */
export async function checkInstalled() {
  if (isAndroid() || isElectronApp()) {
    // if we're running in an app, we are installed
    return true;
  }

  return checkExtensionInstalled();
}

/**
 * Return true if we specifically have the extension installed
 * inside Chrome and we are able to communicate with it
 * @return {Promise<boolean>}
 */
export async function checkExtensionInstalled() {
  // TODO: We plan to deprecate getVersion() once getMetadata() has shipped on extensions
  const value = await getVersion();
  const installed = !!value;
  if (installed) {
    await updateExtensionBrowserName();
  }
  return installed;
}

/**
 * Attempts to communicate with the desktop app on its local server,
 * and returns data if the communication is successful
 * 
 * @param {string} [email]
 * @param {string} [token] 
 * @param {string} [path] 
 * @returns {Promise<object>}
 */
export async function pingDesktopApp(email, token, path) {
  const PING_PORTS = [34242, 43203];
  const PING_URLS = PING_PORTS.map(port => `http://127.0.0.1:${port}`);

  for (const url of PING_URLS) {
    try {
      const urlObj = new URL(url);
      if (path) {
        urlObj.pathname = path;
      }
      if (email) {
        urlObj.searchParams.set('email', email);
      }
      if (token) {
        urlObj.searchParams.set('token', token);
      }
      const fetchURL = urlObj.href;
      const res = await fetch(fetchURL, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const text = await res.text();
      /** @type {{data: string}} */
      const data = JSON.parse(text);
      if (!data.data) {
        // possibly we connected with a different process
        continue;
      }
      return data;
    } catch {
    }
  }
  return {};
}

/**
 * Attempts to communicate with the desktop app on its local server,
 * and returns true if the communication is successful
 * 
 * @param {string} [email]
 * @returns {Promise<boolean>}
 */
export async function checkDesktopAppInstalled(email) {
  // Currently only enabled for Windows or macOS
  if (!isWinPlatform() && !isMacPlatform()) {
    return false;
  }

  const data = await pingDesktopApp(email);
  return data.data === 'Logged In';
}

/**
 * @param {string} [email]
 * @returns 
 */
export async function checkAnyAppInstalled(email) {
  if (await checkExtensionInstalled()) {
    return true;
  }
  if (isElectronApp()) {
    return true;
  }
  if (await checkDesktopAppInstalled(email)) {
    return true;
  }
  return false;
}

/**
 * Only route to desktop app if extension is not installed
 * and the desktop app is installed
 * If URL is provided, check for whitelisted URLs that are
 * only opened in the dashboard
 * 
 * @param {object} arg0
 * @param {{ hash: string, pathname: string }} [arg0.url] the dashboard URL
 * @param {string} [arg0.email] logged in user's email
 * @returns {Promise<boolean>}
 */
export async function shouldRouteToDesktopApp({ url, email } = {}) {
  if (await checkExtensionInstalled()) {
    return false;
  }
  if (await checkDesktopAppInstalled(email)) {
    if (url) {
      // Retain authentication on the browser
      if (['#windowsauth', '#desktopauth', '#deleteaccount'].includes(url.hash)) {
        return false;
      }
      // We only want to open Text Blaze related links in the Text Blaze desktop app
      // Down the line we might expand this list as appropriate
      const URL_WHITELIST = ['/snippet', '/folder', '/group', '/new', '/gallery', '/configure', '/welcome', '/company', '/packs'];
      if (URL_WHITELIST.find(value => url.pathname.startsWith(value))) {
        return true;
      }
      // Open the root URL on desktop
      if (url.pathname === '/') {
        return true;
      }

      // No URL was matched
      return false;
    } else {
      // Docs site links always open in Dashboard
      return true;
    }
  }
  return false;
}

/**
 * @param {{ onlyAISnippets?: boolean, }} [config]
 */
export async function getApplicationStateFromExtension(config = {}) {
  // A unique key ensures that the snippet count is not changed 
  // while chunks are being fetched. In practice this should
  // almost never be necessary because the round trips take a few
  // milliseconds, however, it's good to have this safeguard
  // in place, especially for org users whose total snippet count 
  // changes often due to sharing.
  const chunkAccessKey = Math.random();

  const data = await sendToExtension({
    type: 'application_state',
    chunk: 0,
    chunkAccessKey,
    config,
  });
  if (!data || !data.userData.uid) {
    // Data being undefined this should never occur when used in the browser action,
    // as the extension will be installed. However it can occur during testing.
    //
    // uid may be undefined if firestore data hasn't loaded yet when clicked.
    return null;
  } else {
    let allSnippets = data.snippets;

    if (data.totalChunkCount !== undefined) {
      // Handle snippet data incoming in chunks
      for (let chunk = 1; chunk < data.totalChunkCount; chunk++) {
        const newData = await sendToExtension({ type: 'application_state', chunk, chunkAccessKey });
        if (!newData || newData.totalSnippetCount !== data.totalSnippetCount) {
          // It is unsafe to process chunks in this case, as they can
          // be out of order with each other. So we bail.
          return null;
        }
        allSnippets = allSnippets.concat(newData.snippets);
      }
    }

    return {
      allSnippets,
      data,
    };
  }
}

export function getExtensionLoggedInStatus() {
  return sendToExtension({ type: 'logged_in_status' });
}

/**
 * @param {Object<string, GroupObjectType>} dashboardGroups 
 * @param {string[]} activeGroupIds
 * @returns {Promise<{ isSynced: true } | { isSynced: false, data: object }>}
 */
export async function isExtensionStateSynced(dashboardGroups, activeGroupIds) {
  // TODO: extend this sync check for desktop app later
  const loggedInData = await getExtensionLoggedInStatus();
  if (!loggedInData || !loggedInData.loggedIn) {
    // Extension not installed or it's not logged in
    // When not logged in, the extension is technically out of sync
    // but let's not handle that case here.
    // When the user reloads the dashboard, their extension will
    // automatically get signed in
    return { isSynced: true };
  }
  const data = await getApplicationStateFromExtension();
  if (!data) {
    // If extension is installed, and it doesn't send us data, this happens
    // only when race condition happens during chunk retrieval
    // which is very rare and probably doesn't affect the staleness
    // here, so we keep it simple and just exit here
    return { isSynced: true };
  }

  const THRESHOLD_SECONDS = 60 * 5; // 5 minutes
  /** @type {Object<string, number>} */
  const snippetToUpdatedDate = {};
  for (const snippet of data.allSnippets) {
    snippetToUpdatedDate[snippet.id] = !snippet.updated_at ? null : snippet.updated_at.seconds;
  }

  const timeNow = Math.floor(Date.now() / 1000);
  // The extension doesn't send us snippets from disabled groups, so we 
  // need to make sure here to only check for the enabled groups
  for (const groupId of activeGroupIds) {
    if (!dashboardGroups[groupId]) {
      continue;
    }
    for (const snippet of dashboardGroups[groupId].snippets) {
      const dashboardLatestTime = !snippet.updated_at ? null : snippet.updated_at.seconds;
      const snippetID = snippet.id;
      const extLatestTime = snippetToUpdatedDate[snippetID];
      if (dashboardLatestTime === null || extLatestTime === null) {
        // This snippet does not have the updated_at property
        // Happens on test account for this snippet
        // We use || to intentionally keep this a loose check
        // (we are not worried about false negatives)
        // https://dashboard.blaze.today/snippet/-Kkgy3W-DL2Gzc7HQzbq
        continue;
      }
      if (dashboardLatestTime + THRESHOLD_SECONDS >= timeNow 
             || extLatestTime + THRESHOLD_SECONDS >= timeNow) {
        // If the snippet was updated in the last 5 minutes, ignore it
        // Change may take time to propagate, so we use 5mins to be extra safe
        continue;
      }
      // We removed the !extLatestTime check because we have
      // received many sentry reports where it is missing, even then
      // we really want to catch just the mismatch case
      if (extLatestTime !== dashboardLatestTime) {
        // Snippet exists on dashboard but not in extension
        // OR, snippet in extension was not updated at same time as dashboard
        const data = {
          snippetID,
          extLatestTime,
          dashboardLatestTime,
        };
        return { isSynced: false, data };
      }
    }
  }

  return { isSynced: true };
}

/**
 * @param {string} featureName 
 */
export async function doesSupportsFeature(featureName) {
  if (isElectronApp()) {
    return clientSupportsFeature(featureName);
  }
  const response = await sendToExtension({ type: 'supportsFeature', feature: featureName, });
  return !!response?.supports;
}