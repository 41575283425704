import { captureException } from '@sentry/browser';

/** 
 * @typedef {Object} PrioritizationDataType
 * @property {RawPrioritizationDataType[]} raw tracks individual triggers in an array
 * @property {Object<string, ProcessedPrioritizationDataType>} processed compresses raw data into a mapping from snippet ID to info
 * 
 * @typedef {Object} RawPrioritizationDataType raw snippet trigger event
 * @property {number} u timestamp of snippet trigger
 * @property {string} s snippet ID that was triggered
 * @property {string} d domain on which snippet was triggered
 * 
 * @typedef {Object} ProcessedPrioritizationDataType processed info for one snippet (keyed using its ID)
 * @property {number} u latest triggered timestamp
 * @property {number} n total number of triggers
 * @property {Object<string, number>} d mapping of domain to triggers on that domain
 */
const AI_PRIORITIZATION_KEY = 'aiSnippetPrioritization';

/**
 * 
 * @param {(string) => Promise<PrioritizationDataType>} readData 
 * @param {(string, PrioritizationDataType) => Promise<void>} writeData 
 */
export function prioritizationHandlerMaker(readData, writeData) {
  /**
   * @type {PrioritizationDataType}
   */
  let prioritizationData = null;
  readData(AI_PRIORITIZATION_KEY).then(data => prioritizationData = data || {
    raw: [],
    processed: {}
  });

  /**
   * @param {SnippetObjectType[]} snippets 
   * @param {Pick<GroupObjectType, 'id'|'name'|'options'|'connected'>[]} groups
   * @param {string} hostname
   */
  function getPrioritizedSnippets(snippets, groups, hostname) {
    snippets = snippets.filter(x => x.options.is_ai);
    if (prioritizationData) {
      let timestampTenMinutesAgoInSeconds = Date.now() / 1000 - 10 * 60;
      // order is newly created snippets, followed by snippets used on the domain, followed by others
      /** @type {{ snippet: SnippetObjectType }[]} */
      let prioritizedNEW = [],
        /** @type {{ snippet: SnippetObjectType, priority: ProcessedPrioritizationDataType, }[]} */
        prioritizedDOMAIN = [],
        /** @type {{ snippet: SnippetObjectType, priority?: ProcessedPrioritizationDataType, }[]} */
        prioritizedLOW = [];

      for (let snippet of snippets) {
        // new snippets
        if (snippet.created_at.seconds > timestampTenMinutesAgoInSeconds) {
          prioritizedNEW.push({ snippet });
          continue;
        }

        // no priority data, implies the snippet was not used recently
        let priority = prioritizationData.processed[snippet.id];
        if (!priority) {
          prioritizedLOW.push({ priority, snippet });
          continue;
        }

        // used on the domain
        if (priority.d[hostname]) {
          prioritizedDOMAIN.push({ priority, snippet });
          continue;
        }

        // others
        prioritizedLOW.push({ priority, snippet });
      }
      prioritizedNEW.sort((a, b) => {
        return b.snippet.created_at.seconds - a.snippet.created_at.seconds;
      });
      prioritizedDOMAIN.sort((a, b) => {
        return b.priority.d[hostname] - a.priority.d[hostname];
      });
      prioritizedLOW.sort((a, b) => {
        if (a.priority && !b.priority) {
          return -1;
        }
        if (b.priority && !a.priority) {
          return 1;
        }
        if (!b.priority && !a.priority) {
          // Sort unused snippets in ascending order of their name
          return a.snippet.order - b.snippet.order;
        }
        return b.priority.n - a.priority.n;
      });

      snippets = prioritizedNEW.map(x => x.snippet).concat(prioritizedDOMAIN.map(x => x.snippet)).concat(prioritizedLOW.map(x => x.snippet));
    }

    const validGroups = new Set(snippets.map(x => x.group_id));
    groups = groups.filter(x => validGroups.has(x.id));
    return { groups, snippets, };
  }

  /**
   * @param {string} snippetId 
   * @param {string} hostName 
   * @returns 
   */
  function updatePrioritizationOfSnippets(snippetId, hostName) {
    if (!prioritizationData) {
      // This should never happen
      captureException(new Error('Prioritization data did not load'));
      return;
    }

    // Update raw triggers with this snippet
    prioritizationData.raw.push({
      u: Date.now(),
      s: snippetId,
      d: hostName
    });
    // Store raw triggers only up to the latest 300 entries and up to the past 21 days of usage
    if (prioritizationData.raw.length > 300) {
      prioritizationData.raw = prioritizationData.raw.slice(-300);
    }
    const timestamp21DaysAgoMs = Date.now() - 21 * 24 * 60 * 60 * 1000;
    prioritizationData.raw = prioritizationData.raw.filter(item => item.u > timestamp21DaysAgoMs);
    /** @type {PrioritizationDataType["processed"]} */
    let newProcessed = {};
    for (let usage of prioritizationData.raw) {
      let snippetId = usage.s;
      if (!(snippetId in newProcessed)) {
        newProcessed[snippetId] = { u: -1, d: {}, n: 0 };
      }

      // increment the total usage count
      newProcessed[snippetId].n++;

      // set the last_used timestamp
      newProcessed[snippetId].u = Math.max(newProcessed[snippetId].u, usage.u);

      // increment the domain usage count
      if (!(usage.d in newProcessed[snippetId].d)) {
        newProcessed[snippetId].d[usage.d] = 0;
      }
      newProcessed[snippetId].d[usage.d]++;
    }
    prioritizationData.processed = newProcessed;
    writeData(AI_PRIORITIZATION_KEY, prioritizationData);
  }

  return { getPrioritizedSnippets, updatePrioritizationOfSnippets };
}