/**
 * Initializes a selection at the end of the html, and moves the selection
 * backward one letter at a time, until the selection starts with the cursor
 * string
 * @param {string} html 
 * @param {string} cursor - the cursor placeholder string
 * @param {boolean=} isHTML 
 * @param {boolean=} isForm 
 * 
 * @return {{count: number}} returns the number of backward moves that was required
 */
export function getHTMLInsertionStats(html, cursor, isHTML = true, isForm = false) {
  let clip = document.getElementById('insertion-html');
  if (isForm) {
    clip.style.display = 'block';
  }
  const clonedNode = clip.cloneNode(false);
  clip.parentNode.replaceChild(clonedNode, clip);
  clip = /** @type {HTMLElement} */ (clonedNode);

  const el = document.createElement('span');
  if (isHTML) {
    el.innerHTML = html;
  } else {
    el.innerText = html;
  }
  
  clip.appendChild(el);

  clip.focus({ preventScroll: true });

  // Replace images with 'I' to have them treated as a single character instead of an empty string
  clip.querySelectorAll('img').forEach(function(img) {
    img.replaceWith('I');
  });

  const sel = getSelection();
  sel.selectAllChildren(clip);
  sel.collapseToEnd();

  let txt = '';
  let count = 0;
  // use innerText instead of textContent to make sure we get the line breaks
  const maxLength = el.innerText.length;
  let lastLength = -1;
  let retried = false;

  while (!txt.startsWith(cursor)) {
    // @ts-ignore - Selection.modify is non-standard
    sel.modify('extend', 'left', 'character');
    count += 1;
    txt = sel.toString();
    const newLength = txt.length;
    if (newLength === lastLength) {
      if (retried) {
        return { count: 0 };
      }
      // In case the snippet HTML has a bare <br />
      // element, which can happen on Google Docs
      // then it will require pressing Shift-Left twice
      // to get to the correct position
      // It also happened in the desktop in a snippet with a table and the cursor is present before the table
      retried = true;
    } else if (newLength > maxLength) {
      return { count: 0 };
    } else {
      retried = false;
    }
    lastLength = newLength;
  }

  count -= cursor.length;

  return { count };
}
